<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <el-card>
          <h1>个人资料</h1>
          <br/>
          <div class="col-xl-12 l-row">
            <el-upload
                class="avatar-uploader inline-block"
                :headers="headers"
                :data="userData"
                action="/api/userDetails/uploadPhoto"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>


            <div class="notice-text">请上传白底免冠证件照（要求：照片格式统一为“.jpg”），此项信息用于制作校园一卡通，务必按照要求认真填写并上传照片。样例：</div>

            <div class="example-pic-div">
              <img style="border: 1px solid #000000;" class="example-pic"
                   src="media/source/example2.jpeg"></div>
          </div>
          <br/>
          <div class="big-container" v-for="(item, index) in tableList" :key="index">
            <div class="col-xl-12 myInput">
              <!-- <h4 class="labelBox">{{  }}:</h4> -->
              <div class="inputBox">
                <el-input
                    v-model="form[item.en]"
                    :disabled="item.disabled"
                    :placeholder="item.cn"
                ></el-input>
              </div>
            </div>
            <div
                class="col-xl-12 myInput" v-if="!item.disabled&&!item.checked"
            >
              <div class="information">
                * 请补充信息,不能为空
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="my-button-div">
              <button
                  class="btn btn-primary my-button"
                  @click="saveUserDetails()"
              >
                保存
              </button>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      imageUrl: "",
      headers: {
        Authorization: sessionStorage.getItem("systemToken"),
      },
      userData: {
        idNumber: 32101212222111124,
        name: "liywhoa",
      },
      form: {
        name: "",
        sex: "",
        idNumber: "",
        nation: "",
        studentSourceDetail: "",
        city: "",
        major: "",
        bornYearMonth: "",
        politicalOutlook: "",
        phone: "",
        familyAddress: "",
        graduateSchool: "",
        headTeacherName: "",
        headTeacherPhone: "",
        parentName1: "",
        parentRelationShip1: "",
        parentUnit1: "",
        parentPhone1: "",
        parentName2: "",
        parentRelationShip2: "",
        parentUnit2: "",
        parentPhone2: "",
        studentNumber: "",
        pay: "",
        department: "",
        grade: "",
        email: "",
      },
      tableList: [
        {cn: "姓名 ", en: "name", disabled: true, checked: true},
        {cn: "性别", en: "sex", disabled: true, checked: true},
        {cn: "身份证号码", en: "idNumber", disabled: true, checked: true},
        {cn: "民族", en: "nation", disabled: true, checked: true},
        {cn: "生源地", en: "studentSourceDetail", disabled: true, checked: true},
        {cn: "城市", en: "city", disabled: false, checked: true},
        {cn: "录取专业", en: "major", disabled: true, checked: true},
        {cn: "出生年月", en: "bornYearMonth", disabled: true, checked: true},
        {cn: "政治面貌", en: "politicalOutlook", disabled: true, checked: true},
        {cn: "手机号码", en: "phone", disabled: false, checked: true},
        {cn: "电子邮箱", en: "email", disabled: false, checked: true},
        {cn: "家庭住址", en: "familyAddress", disabled: false, checked: true},
        {cn: "毕业中学", en: "graduateSchool", disabled: true, checked: true},
        {cn: "高三班主任姓名", en: "headTeacherName", disabled: false, checked: true},
        {cn: "高三班主任电话", en: "headTeacherPhone", disabled: false, checked: true},
        {cn: "家长1 姓名", en: "parentName1", disabled: false, checked: true},
        {cn: "家长1 关系 例：父子、父女、母子、母女等", en: "parentRelationShip1", disabled: false, checked: true},
        {cn: "家长1 单位", en: "parentUnit1", disabled: false, checked: true},
        {cn: "家长1 电话", en: "parentPhone1", disabled: false, checked: true},
        {cn: "家长2 姓名", en: "parentName2", disabled: false, checked: true},
        {cn: "家长2 关系 例：父子、父女、母子、母女等", en: "parentRelationShip2", disabled: false, checked: true},
        {cn: "家长2 单位", en: "parentUnit2", disabled: false, checked: true},
        {cn: "家长2 电话", en: "parentPhone2", disabled: false, checked: true},

      ],
    };
  },
  methods: {
    initUserDetails() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/userDetails/getUserDetailsByUserId", //后端请求的路径
        method: "post",
      }).then((response) => {
        let UserDetails = response.userDetails;
        this.imageUrl = response.userDetails.avatarPath;
        UserDetails.pay = UserDetails.pay ? "是" : "否";
        this.form = UserDetails;
      });
    },
    saveUserDetails() {
      if (this.imageUrl === "") {
        this.$message({
          message: "请先上传照片！",
          type: "error",
        });
      } else {
        if (this.checkValue()) {
          let res = this.form;
          res.pay = "";
          axios({
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: sessionStorage.getItem("systemToken"),
            },
            url: "/api/userDetails/updateUserDetails", //后端请求的路径
            method: "post",
            data: res,
          }).then((response) => {
            if (response.status === 0) {
              this.$message({
                message: "更新数据成功",
                type: "success",
              });
              window.scrollTo(0, 0);

              if (this.$route.path === "/main") {
                this.$parent.openExtraInfoForm();
              }
            } else {
              this.$message({
                message: "发生错误，原因：" + response.msg,
                type: "error",
              });
            }
          });
        } else {
          this.$message({
            message: "请完善信息",
            type: "error",
          });
        }
      }
    },
    handleAvatarSuccess(res, file) {
      this.$message.success("更新成功!");
      let url = URL.createObjectURL(file.raw);
      this.imageUrl = url;
      this.$store.commit("changeAvatarPath", url);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    },
    checkValue() {
      let flag = true;
      for (let index in this.tableList) {
        let item = this.tableList[index]
        if (this.form.graduateSchool === '' || this.form.graduateSchool === null) {
          this.form.graduateSchool = "无";
          if (this.form[item.en] === "" || this.form[item.en] === null) {
            this.$set(this.tableList[index], "checked", false)
            flag = false;
          } else {
            this.$set(this.tableList[index], "checked", true)
          }
        }
        if (this.form[item.en] === "" || this.form[item.en] === null) {
          this.$set(this.tableList[index], "checked", false)
          flag = false;
        } else {
          this.$set(this.tableList[index], "checked", true)
        }
      }
      return flag;
    }
  },
  mounted() {
    this.initUserDetails();
  },
};
</script>
<style scoped>
.myInput {
  display: inline-flex;
  margin-bottom: 8px;
}

.inputBox {
  width: 100%;
}

.labelBox {
  text-align: left;
  width: 30%;
  margin-top: 10px;
}

.my-button-div {
  width: 10rem;
  margin: auto;
}

.my-button {
  margin-top: 20px;

  width: 100%;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 150px;
  line-height: 135px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 150px;
  line-height: 75px;
}


.example-pic {
  height: 150px;

}

.notice-text {
  margin: 5px 20px 5px 20px;
  font-size: 13px;
  width: 200px;
}

.example-pic-div {
  margin-left: 20px;
}

.l-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.inline-block {
  display: inline-block;

}

.information {
  color: red;
}

</style>
